<template>
  <div class="open-box">
    <div class="history-header">
      <div class="search-box">
        <div class="date-box" @click="openCalendar">
          <div>
            <span class="date-item">{{ searchData.startTime }}</span>
            <span class="date-border">-</span>
            <span class="date-item">{{ searchData.endTime }}</span>
          </div>
          <img :src="require('@/assets/image/search.svg')" class="icon-search" />
        </div>
      </div>
    </div>
    <div class="table-list">
      <ml-echarts id="a232" :option="lineHistoryOptions(echartsData.data, 1)" />
    </div>
    <div class="table-list">
      <el-table :data="tabberData.data" style="width: 100%" border>
        <el-table-column align="center" label="时间" prop="time" min-width="10%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          :label="propertyName + ' ' + unit"
          prop="propertyText"
          min-width="10%"
        />
      </el-table>
    </div>
    <van-calendar
      v-model="searchData.date"
      :default-date="searchData.date"
      v-model:show="showCalendar"
      type="range"
      @confirm="changeTimeHandle"
      :min-date="minDate"
      :max-date="maxDate"
    />
  </div>
</template>
<script>
import { onMounted, ref, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import lineHistoryOptions from '@utils/viewData/lineHistoryOptions'
import { formetData } from '@/utils'
export default {
  setup() {
    const route = useRoute()
    const { dispatch } = useStore()
    const { deviceId, propertyCode } = route.query

    let date = new Date()
    let endTime = formetData(date)
    let startTime = formetData(new Date(endTime).getTime() - 7 * 24 * 60 * 60 * 1000)
    let searchData = reactive({
      date: [new Date(startTime), date],
      deviceId: deviceId,
      propertyCode: propertyCode,
      startTime: startTime,
      endTime: endTime
    })
    let propertyName = ref('')
    let unit = ref('')
    let showCalendar = ref(false)
    let tabberData = reactive({
      data: [],
      pageNo: 1,
      pageSize: 30,
      total: 0
    })
    let echartsData = reactive({
      data: []
    })
    const getListHandle = () => {
      let params = {
        deviceId: searchData.deviceId,
        propertyCode: searchData.propertyCode,
        startTime: searchData.startTime,
        endTime: searchData.endTime
      }
      dispatch('fetchRtuAttrHisList', params).then(response => {
        if (response.code === 200) {
          echartsData.data = response.data.attrList
          let list = JSON.parse(JSON.stringify(response.data.attrList))
          tabberData.data = list.reverse()
          propertyName.value = response.data.propertyName
          unit.value = (echartsData.data && echartsData.data[0] && echartsData.data[0].unit) || ''
        }
      })
    }
    const getListPageHandle = () => {
      let params = {
        deviceId: searchData.deviceId,
        propertyCode: searchData.propertyCode,
        startTime: searchData.startTime,
        endTime: searchData.endTime,
        pageIndex: tabberData.pageNo,
        pageSize: tabberData.pageSize
      }
      // dispatch('fetchRtuAttrHisListPage', params).then(response => {
      //   if (response.code === 200) {
      //     let list = response.data
      //     if (tabberData.pageNo === 1) {
      //       tabberData.data = list
      //     } else {
      //       tabberData.data = tabberData.data.concat(...list)
      //     }

      //     tabberData.total = response.total || 0
      //     if (tabberData.pageNo * tabberData.pageSize < tabberData.total) {
      //       setTimeout(() => {
      //         tabberData.pageNo++
      //         getListPageHandle()
      //       }, 500)
      //     }
      //   }
      // })
    }
    const calendarRef = ref()
    const openCalendar = () => {
      showCalendar.value = true
    }
    const changeTimeHandle = date => {
      if (date && date.length > 0) {
        searchData.startTime = formetData(date[0])
        searchData.endTime = formetData(date[1])
        tabberData.pageNo = 1
        getListHandle()
        getListPageHandle()
        showCalendar.value = false
      }
    }
    onMounted(() => {
      if (deviceId) {
        getListHandle()
        getListPageHandle()
      }
    })
    return {
      deviceId,
      propertyCode,
      lineHistoryOptions,
      getListHandle,
      propertyName,
      searchData,
      tabberData,
      unit,
      echartsData,
      showCalendar,
      openCalendar,
      changeTimeHandle,
      calendarRef,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31)
    }
  }
}
</script>
<style lang="scss" scoped>
.open-box {
  padding: 16px 10px 30px;
}
.table-list {
  height: 300px;
  margin-bottom: 50px;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-box {
  width: 365px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  padding: 0 15px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}
.date-border {
  margin: 0 10px;
}
.icon-search {
  width: 20px;
  height: 20px;
}
</style>
<style>
.calendar_title_date {
  /* visibility: hidden */
}
</style>
export default (data, type) => {
  let nData = []
  let time = []
  let propertyName = ''
  let dataEnd = 10
  if (data && data.length > 0) {
    data.forEach(d => {
      time.push(d.time)
      nData.push(Number(d.propertyValue))
      propertyName = d.propertyName
    })
    if (data.length > 3000) {
      dataEnd = 5
    }
  }
  let options = {
    tooltip: {
      trigger: 'axis',
      position: function (pt) {
        return [pt[0], '']
      }
    },
    grid: {
      left: '10%',
      right: '10%'
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: time,
      axisLabel: {}
    },
    yAxis: {
      type: 'value',
      name: data[0] && data[0].unit || '',
      nameLocation: 'end',
      nameTextStyle: {
        align: 'right'
      }
    },
    dataZoom: [{
        type: 'slider',
        start: 0,
        end: dataEnd
      },
      {
        start: 0,
        end: 20
      }
    ],
    series: [{
      name: propertyName,
      type: 'line',
      smooth: true,
      symbol: 'none',
      areaStyle: {},
      data: nData
    }]
  }
  if (type == 1) {
    options.xAxis.axisLabel.formatter = function (params) {
      let list = params.split(' ')
      let newParamsName = "";
      newParamsName = list[0] + '\n' + list[1]
      return newParamsName
    }
    options.dataZoom.forEach(d => {
      d.bottom = 5
    })
  }
  return options
}